const iconNames = {
    // alert: 'ion-md-alert',
    alert: 'ion-android-alert',
    menu: 'ion-android-menu',
    check: 'ion-checkmark',
    close: 'ion-close',
    layers: 'ion-social-buffer',
    share: 'ion-android-share-alt',

    sort: 'fa fa-sort',
    sortAscending: 'fa fa-sort-asc',
    sortDescending: 'fa fa-sort-desc',

    dashboard: 'ion-android-home',
    drought: 'ion-leaf',
    riskMap: 'ion-android-map',
    riskInfo: 'ion-android-alert',
    incidents: 'ion-help-circled', // TODO remove this
    incident: 'ion-help-circled',
    lossAndDamange: 'ion-ios-clock',
    drrProjectsProfile: 'ion-filing',
    disasterProfile: 'ion-podium',
    profile: 'ion-podium',
    aboutUs: 'ion-ios-information',
    realtime: 'ion-android-wifi',
    indicator: 'ion-ios-speedometer',
    resource: 'ion-android-globe',
    rainicon: 'rainIcon',
    rainiconactive: 'rainIconActive',
    rivericon: 'riverIcon',
    rivericonactive: 'riverIconActive',

    filter: 'ion-android-funnel',
    chevronUp: 'ion-chevron-up',
    chevronDown: 'ion-chevron-down',
    chevronRight: 'ion-chevron-right',
    chevronLeft: 'ion-chevron-left',

    calendar: 'ion-ios-calendar-outline',
    location: 'ion-ios-location-outline',
    gps: 'ion-ios-location',
    distance: 'ion-android-locate',

    expand: 'ion-arrow-expand',
    shrink: 'ion-arrow-shrink',

    play: 'ion-ios-play',
    pause: 'ion-ios-pause',

    list: 'ion-ios-list-outline',
    pie: 'ion-ios-pie-outline',
    pulse: 'ion-ios-pulse-strong',

    telephone: 'ion-ios-telephone',
    user: 'ion-android-person',

    login: 'ion-log-in',
    logout: 'ion-log-out',
    back: 'ion-android-arrow-back',

    email: 'ion-ios-email',
    star: 'ion-ios-star',
    upload: 'ion-upload',
    cloudDownload: 'ion-android-download',

    eye: 'ion-eye',
    eyeOff: 'ion-eye-off',
    basin: 'fa-square',

    sliders: 'fa fa-sliders',
    table: 'fa fa-table',
    contrast: 'ion-contrast',

    checkmarkCircle: 'ion-checkmark-circled',
    infoOutline: 'ion-ios-information-outline',
    settings: 'ion-ios-settings-strong',
    bars: 'ion-stats-bars',

    map: 'ion-map',
    warning: 'ion-android-warning',
    dataRange: 'ion-android-time',

    refresh: 'ion-android-refresh',
    send: 'ion-android-send',

    gridView: 'ion-ios-grid-view-outline',

    externalLink: 'ion-android-exit',
    link: 'ion-link',
    chatBoxes: 'ion-chatboxes',
    chatBox: 'ion-chatbox',
    edit: 'ion-compose',

    contacts: 'ion-ios-contact-outline',
    briefcase: 'ion-briefcase',
    document: 'ion-android-document',
    textDocument: 'ion-document-text',
    cart: 'ion-ios-cart-outline',
    cog: 'ion-ios-cog',
    circle: 'fa fa-circle',
    square: 'fa fa-square',

    lock: 'fa fa-unlock-alt',
    times: 'fa fa-times',
    info: 'fa  fa-info-circle',
    arrowDown: 'fa fa-caret-down',
    plus: 'fa fa-plus',
    language: 'fa fa-language',
    search: 'fa fa-search',
    home: 'fa fa-home',

    sortDown: 'fa fa-angle-down',
    sortUp: 'fa fa-angle-up',
    chart: 'fa fa-line-chart',
    cloud: 'fa fa-cloud',
    filePdf: 'fa fa-file-pdf-o',


    education: 'fa fa-graduation-cap',
    dropdown: 'fa fa-caret-down',
    dropRight: 'fa fa-caret-right',
    capResAddFormDropdown: 'fa fa-angle-down',
    phoneContact: 'fas fa-phone-alt',
    tableView: 'fa fa-list-ul',
    lgprofilePopupDownArrow: 'fa fa-chevron-down',
    lgprofilePopupUpArrow: 'fa fa-chevron-up',
    feedbackIcon: 'fa fa-comments',
    fullScreen: 'fa fa-arrows-alt',


};

export default iconNames;
