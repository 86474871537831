/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/indent */
import PropTypes from 'prop-types';
import React from 'react';
import {
    padStart,
    getErrorForDateValues,
    getNumDaysInMonthX,
    isFalsy,
    decodeDate as decodeAsDate,
    _cs,
} from '@togglecorp/fujs';
import { FaramInputElement } from '@togglecorp/faram';

import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { ADToBS } from 'bikram-sambat-js';
import FloatingContainer from '../../View/FloatingContainer';
import Delay from '../../General/Delay';
import DatePicker from '../DatePicker';

import HintAndError from '../HintAndError';
import Label from '../Label';
import DigitalInput from '../DigitalInput';
import {
    calcFloatPositionInMainWindow,
    defaultOffset,
    defaultLimit,
} from '../../../utils/bounds';

import ActionButtons from './ActionButtons';
import styles from './styles.scss';
import 'nepali-datepicker-reactjs/dist/index.css';

const propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    hint: PropTypes.string,
    error: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    showLabel: PropTypes.bool,
    showHintAndError: PropTypes.bool,
    value: PropTypes.string,
    title: PropTypes.string,
    separator: PropTypes.string,
    inputFieldClassName: PropTypes.string,

};

const defaultProps = {
    inputFieldClassName: '',
    className: '',
    disabled: false,
    error: '',
    hint: '',
    label: '',
    onChange: () => { },
    showLabel: true,
    separator: '-',
    readOnly: false,
    value: undefined,
    showHintAndError: true,
    title: undefined,
};

const MIN_YEAR = 1900;
const MAX_YEAR = 9999;
const MIN_MONTH = 1;
const MAX_MONTH = 12;
const MIN_DAY = 1;
const STEP = 1;

const createDate = (y, m, d) => {
    if (getErrorForDateValues({ yearValue: y, monthValue: m, dayValue: d })) {
        return undefined;
    }
    return new Date(y, m - 1, d);
};

// y, m, d is string
const encodeDate = ({ y = '', m = '', d = '' }, separator = '-') => {
    if (isFalsy(y, [0, '']) && isFalsy(m, [0, '']) && isFalsy(d, [0, ''])) {
        return undefined;
    }
    return `${y}${separator}${m}${separator}${d}`;
};

// value is string
const decodeDate = (value, separator = '-') => {
    if (!value) {
        return {};
    }
    const values = value.split(separator);
    return {
        y: values[0],
        m: values[1],
        d: values[2],
    };
};

// value is string
export const isValidDateString = (value, separator = '-') => {
    if (value === '' || value === undefined) {
        return true;
    }
    const { y, m, d } = decodeDate(value, separator);

    return !getErrorForDateValues({ yearValue: +y, monthValue: +m, dayValue: +d });
};
class DateInput extends React.Component {
    static propTypes = propTypes;

    static defaultProps = defaultProps;

    constructor(props) {
        super(props);

        this.state = {
            yearInputFocused: false,
            monthInputFocused: false,
            dayInputFocused: false,
            showDatePicker: false,
            containerHover: false,
            nepaliDate: '',
        };

        this.containerRef = React.createRef();
        this.boundingClientRect = {};
    }

    componentDidMount() {
        const { current: container } = this.containerRef;
        if (container) {
            this.boundingClientRect = container.getBoundingClientRect();
        }
    }

    getClassName = () => {
        const {
            disabled,
            className,
            value,
            error,
            separator,
            readOnly,
            languageMargin,
        } = this.props;
        const {
            yearInputFocused,
            monthInputFocused,
            dayInputFocused,
        } = this.state;

        const classNames = [
            className,
            'date-input',
            styles.dateInput,
        ];

        if (languageMargin && !!languageMargin) {
            classNames.push(styles.langMargin);
        }

        if (yearInputFocused || monthInputFocused || dayInputFocused) {
            classNames.push(styles.focused);
            classNames.push('input-focused');
            classNames.push('input-in-focus');
        }

        if (disabled) {
            classNames.push(styles.disabled);
            classNames.push('disabled');
        }

        const isInvalid = !isValidDateString(value, separator);
        if (isInvalid) {
            classNames.push(styles.invalid);
            classNames.push('invalid');
        }

        if (error) {
            classNames.push(styles.error);
            classNames.push('error');
        }

        if (readOnly) {
            classNames.push('read-only');
            classNames.push(styles.readOnly);
        }

        return classNames.join(' ');
    }

    handleYearInputFocus = () => {
        this.setState({ yearInputFocused: true });
    }

    handleYearInputBlur = () => {
        this.setState({ yearInputFocused: false });
    }

    handleMonthInputFocus = () => {
        this.setState({ monthInputFocused: true });
    }

    handleMonthInputBlur = () => {
        this.setState({ monthInputFocused: false });
    }

    handleDayInputFocus = () => {
        this.setState({ dayInputFocused: true });
    }

    handleDayInputBlur = () => {
        this.setState({ dayInputFocused: false });
    }

    handleYearInputChange = (y) => {
        this.handleChange({ y });
    }

    handleMonthInputChange = (m) => {
        this.handleChange({ m });
    }

    handleDayInputChange = (d) => {
        this.handleChange({ d });
    }

    handleClearButtonClick = () => {
        this.handleChange({
            y: undefined,
            m: undefined,
            d: undefined,
        });
    }

    handleTodayButtonClick = () => {
        const { language } = this.props;
        const date = new Date();

        if (language === 'np') {
            const dateString = date.toLocaleDateString();
            const nepDateString = ADToBS(dateString);
            const vals = nepDateString.split('-');

            this.handleChange({
                y: vals[0],
                m: vals[1],
                d: vals[2],
            });
            // decodeDate(nepDateString);
        } else {
            this.handleChange({
                y: padStart(String(date.getFullYear()), 4).slice(-4),
                m: padStart(String(date.getMonth() + 1), 2).slice(-2),
                d: padStart(String(date.getDate()), 2).slice(-2),
            });
        }
    }

    handleCalendarButtonClick = () => {
        const { language, className } = this.props;
        const { current: container } = this.containerRef;
        this.boundingClientRect = container.getBoundingClientRect();
        this.setState({ showDatePicker: true });

        const dateselect = document.querySelectorAll('.nepaliDatePicker');
        if (className === 'startDateInput') {
            if (dateselect[0] && language === 'np') {
                dateselect[0].click();
            }
            return;
        }
        if (className === 'endDateInput') {
            if (dateselect[1] && language === 'np') {
                dateselect[1].click();
            }
            return;
        }
        if (className === 'startEndDateInput') {
            if (dateselect[2] && language === 'np') {
                dateselect[2].click();
            }
        }
    }

    handleDatePickerBlur = () => {
        this.setState({ showDatePicker: false });
    }

    handleDatePickerInvalidate = (datePickerContainer) => {
        const contentRect = datePickerContainer.getBoundingClientRect();

        const { current: container } = this.containerRef;
        const parentRect = container
            ? container.getBoundingClientRect()
            : this.boundingClientRect;

        const { showHintAndError } = this.props;
        const offset = { ...defaultOffset };
        if (showHintAndError) {
            offset.top = 12;
        }

        const optionsContainerPosition = calcFloatPositionInMainWindow({
            parentRect,
            contentRect,
            offset,
            limit: {
                ...defaultLimit,
                minW: parentRect.width,
            },
        });

        return optionsContainerPosition;
    }


    nepaliDatePickerHandler = (value) => {
        this.setState({ nepaliDate: value });
        this.handleDatePickerDatePick();
    }

    handleChange = (valueToOverride) => {
        const {
            onChange,
            value: valueFromProps,
            separator,
        } = this.props;

        const oldValue = decodeDate(valueFromProps, separator);
        const { y, m, d } = {
            ...oldValue,
            ...valueToOverride,
        };

        const newValue = encodeDate({ y, m, d }, separator);
        if (newValue !== valueFromProps) {
            onChange(newValue);
        }
    }

    handleDatePickerDatePick = (timestamp) => {
        const { language } = this.props;
        const newDate = decodeAsDate(timestamp);
        const { nepaliDate } = this.state;

        if (language === 'np') {
            const vals = nepaliDate && [...nepaliDate.split('-')];
            const nepaliYear = vals[0];
            const nepaliMonth = vals[1];
            const nepaliDay = vals[2];


            this.setState(
                { showDatePicker: false },
                () => {
                    this.handleChange({
                        y: nepaliYear,
                        m: nepaliMonth,
                        d: nepaliDay,
                    });
                },
            );
            return;
        }
        this.setState(
            { showDatePicker: false },
            () => {
                this.handleChange({
                    y: padStart(String(newDate.getFullYear()), 4).slice(-4),
                    m: padStart(String(newDate.getMonth() + 1), 2).slice(-2),
                    d: padStart(String(newDate.getDate()), 2).slice(-2),
                });
            },
        );
    }

    handleActionButtonsInvalidate = (actionButtonsContainer) => {
        const { current: container } = this.containerRef;
        const parentRect = container
            ? container.getBoundingClientRect()
            : this.boundingClientRect;

        const contentRect = actionButtonsContainer.getBoundingClientRect();

        const { showHintAndError } = this.props;

        const actionButtonsContainerPosition = {
            left: `${(parentRect.left + parentRect.width) - contentRect.width}px`,
            top: `${(parentRect.top + parentRect.height) - (showHintAndError ? 12 : 0)}px`,
        };

        return actionButtonsContainerPosition;
    }

    handleContainerHover = (event) => {
        if (this.containerRef.current && this.containerRef.current.contains(event.target)) {
            this.setState({ containerHover: true });
        }
    }

    handleContainerLeave = (event) => {
        // Previous Code//

        // if (this.containerRef.current && this.containerRef.current.contains(event.target)) {
        //     this.setState({ containerHover: false });
        // }

        if (this.containerRef.current) {
            this.setState({ containerHover: false });
        }
    }

    renderDatePicker = ({ y, m, d }) => {
        const date = createDate(+y, +m, +d);
        const datetime = date && date.getTime();
        return (
            <FloatingContainer
                className={styles.datePickerContainer}
                parent={this.container}
                onBlur={this.handleDatePickerBlur}
                onInvalidate={this.handleDatePickerInvalidate}
            >
                <DatePicker
                    value={datetime}
                    onChange={this.handleDatePickerDatePick}
                />
            </FloatingContainer>
        );
    }

    renderNepaliDatePicker = ({ nepaliDate, optimizePosition }) => (
        <NepaliDatePicker
            inputClassName={'nepaliDatePicker'}
            className={optimizePosition ? styles.nepaliCalenderCapacity : styles.nepaliCalender}
            value={nepaliDate}
            onChange={value => this.nepaliDatePickerHandler(value)}
            options={{ calenderLocale: 'ne', valueLocale: 'en' }}
        />
    )


    render() {
        const {
            error,
            hint,
            label,
            showLabel,
            showHintAndError,
            title,
            disabled,
            value,
            readOnly,
            separator,
            inputFieldClassName,
            language,
            optimizePosition,
        } = this.props;
        const {
            showDatePicker,
            containerHover,
            nepaliDate,
        } = this.state;

        const className = this.getClassName();
        const yearPlaceholder = language === 'en' ? 'yyyy' : 'वर्ष';
        const monthPlaceholder = language === 'en' ? 'mm' : 'महिना';
        const dayPlaceholder = language === 'en' ? 'dd' : 'दिन';

        const {
            y: yearValue = '',
            m: monthValue = '',
            d: dayValue = '',
        } = decodeDate(value, separator);

        const FloatingDatePicker = this.renderDatePicker;
        const FloatingNepaliDatePicker = this.renderNepaliDatePicker;
        const inputAndActionsClassName = `
            ${styles.input}
            input-and-actions
        `;
        return (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <div
                ref={this.containerRef}
                title={title}
                className={className}
                onMouseOver={e => this.handleContainerHover(e)}
                onMouseLeave={e => this.handleContainerLeave(e)}
            >
                <Label
                    className={styles.label}
                    show={showLabel}
                    text={label}
                />
                <div className={inputAndActionsClassName}>
                    <div className={_cs(inputFieldClassName, styles.units)}>
                        <DigitalInput
                            onFocus={this.handleDayInputFocus}
                            onBlur={this.handleDayInputBlur}
                            className={styles.dayUnit}
                            padLength={2}
                            min={MIN_DAY}
                            max={getNumDaysInMonthX(+yearValue, +monthValue)}
                            step={STEP}
                            placeholder={dayPlaceholder}
                            disabled={disabled || readOnly}
                            value={dayValue}
                            onChange={this.handleDayInputChange}
                        />
                        <DigitalInput
                            onFocus={this.handleMonthInputFocus}
                            onBlur={this.handleMonthInputBlur}
                            className={styles.monthUnit}
                            padLength={2}
                            min={MIN_MONTH}
                            max={MAX_MONTH}
                            step={STEP}
                            placeholder={monthPlaceholder}
                            disabled={disabled || readOnly}
                            value={monthValue}
                            onChange={this.handleMonthInputChange}
                        />
                        <DigitalInput
                            onFocus={this.handleYearInputFocus}
                            onBlur={this.handleYearInputBlur}
                            className={styles.yearUnit}
                            padLength={4}
                            min={MIN_YEAR}
                            max={MAX_YEAR}
                            step={STEP}
                            placeholder={yearPlaceholder}
                            disabled={disabled || readOnly}
                            value={yearValue}
                            onChange={this.handleYearInputChange}
                        />
                    </div>
                    {containerHover && (
                        <ActionButtons
                            className={styles.actionButtons}
                            disabled={disabled}
                            readOnly={readOnly}
                            onClearButtonClick={this.handleClearButtonClick}
                            onTodayButtonClick={this.handleTodayButtonClick}
                            onCalendarButtonClick={this.handleCalendarButtonClick}
                            onInvalidate={this.handleActionButtonsInvalidate}
                        />
                    )}
                </div>
                <HintAndError
                    show={showHintAndError}
                    hint={hint}
                    error={error}
                />
                {(showDatePicker && language === 'en') && (
                    <FloatingDatePicker
                        y={yearValue}
                        m={monthValue}
                        d={dayValue}
                    />
                )}
                {(language === 'np')
                    && (
                        <FloatingNepaliDatePicker nepaliDate={nepaliDate} optimizePosition={optimizePosition} />
                    )
                }
            </div>
        );
    }
}

export default FaramInputElement(Delay(DateInput));
