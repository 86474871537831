import ReactGA from 'react-ga4';

/**
 * Dimensions used to store data are as follows:
 * dimension1 : Logged in User
 * dimension2 : Module
 * dimension3 : Name of Downloaded File
 * dimension4 : Internal Pages visit with click without changing route
 * dimension5 : Risk Info Layer Group
 * dimension6 : Risk Info Layer
 * dimension7 : Reference Path
 *             = This value tracks the internal pages if there are anonymus number of internal pages
*              = For Eg: In Risk Info : Hazard/Flood/Flood Hazard/Narayani.
*/

interface Dimensions {
    dimension1?: string;
    dimension2?: string;
    dimension3?: string;
    dimension4?: string;
    dimension5?: string;
    dimension6?: string;
    dimension7?: string;
}

interface DimensionMap {
    authUser?: string;
    moduleName?: string;
    downloadedFile: string;
    internalPage: string;
    layerGroup: string;
    layer: string;
    refPath: string;
}

// map key to dimension
const getDimensionMapping = (): DimensionMap => ({
    authUser: 'dimension1',
    moduleName: 'dimension2',
    downloadedFile: 'dimension3',
    internalPage: 'dimension4',
    layerGroup: 'dimension5',
    layer: 'dimension6',
    refPath: 'dimension7',
});


const getDimensionFromMapping = (key: string): string => {
    const dimensionMap = getDimensionMapping();
    return dimensionMap[key as keyof typeof dimensionMap];
};

const prepareDimensionObj = (dimensions: DimensionMap): Dimensions => {
    const dimensionObj: Dimensions = {
        dimension1: '',
        dimension2: '',
        dimension3: '',
        dimension4: '',
        dimension5: '',
        dimension6: '',
        dimension7: '',

    };
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(dimensions)) {
        const dimension = getDimensionFromMapping(key);
        if (dimension) {
            dimensionObj[dimension] = value;
        }
    }
    return dimensionObj;
};

// This will initialize Google Analytics 4
const initializeGa4 = () => {
    ReactGA.initialize([
        {
            trackingId: `${process.env.REACT_APP_GA_MEASUREMENT_ID}`,
        },
    ]);
};


// This will collect page view
const collectPageViewData = (dimensions: DimensionMap): void => {
    const dimensionObj = prepareDimensionObj(dimensions);
    const pageObject = ReactGA.ga(() => {
        ReactGA.send({
            hitType: 'pageview',
            ...dimensionObj,
        });
    });
};

// this will collect action data
const collectActionData = (category: any, action: any, dimensions: DimensionMap): void => {
    const eventObject = {
        category,
        action,
    };
    const dimensionObj = prepareDimensionObj(dimensions);
    ReactGA.event({ ...eventObject, ...dimensionObj });
};

export { initializeGa4, collectPageViewData, collectActionData };
